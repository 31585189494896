.features {
  grid-column: center-start / center-end;
  background: $color-white;
  margin: 15rem 0;

  @supports (display: grid) {
    display: grid;
    //grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    grid-gap: 6rem;
    align-items: start;
  }
}

.feature {
  float: left;
  width: 33.3333%;
  margin-bottom: 6rem;

  @supports (display: grid) {
    width: auto;
    margin-bottom: 0;
  }

  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 2.5rem;

  &__icon {
    fill: $color-primary;
    width: 4.5rem;
    height: 4.5rem;
    grid-row: 1 / span 2;
    transform: translateY(-1rem);
  }

  &__text {
    font-size: 1.7rem;
  }
}
