.btn {
  background-color: $color-primary;
  color: #fff;
  border: none;
  border-radius: 0;
  font-family: $font-display;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.8rem 3rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: $color-primary-dark;
  }
}

.btn__big {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(
    to right,
    $color-primary,
    $color-primary-dark,
    $color-secondary
  );
  background-size: 200%;
  font-size: 1.6rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 0;
  cursor: pointer;
  transition: 0.5s;
}
.btn__big:hover {
  background-position: right;
}
