.contact {
  grid-column: center-start / center-end;
  padding: 0rem 8vw 6rem 8vw;

  display: grid;
  @media only screen and (max-width: $bp-small) {
    padding: 6rem 0;
  }
}
.form {
  transition: all 0.3s;
  &__group:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__input,
  &__request {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1.5rem 2rem;
    border-radius: 2px;
    background-color: rgba($color-grey-light-2, 0.3);
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all 0.3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border-bottom: 3px solid $color-primary;
    }

    &:focus:invalid {
      border-bottom: 3px solid $color-red;
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }
  }

  &__label {
    font-size: 1.3rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }
}

.error {
  transition: opacity 500ms ease-in-out;
}
.error_label {
  color: $color-red;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  margin: 0px auto;
  transition: opacity 500ms ease-in-out;
}
.success_label {
  color: $color-primary;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  margin: 0px auto;
  transition: opacity 500ms ease-in-out;
}
