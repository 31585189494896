.property__filters {
  grid-column: center-start / center-end;

  & .tag {
    font-weight: 300;
    font-size: 1.5rem;
    margin-right: 2rem;
    color: $color-secondary-2;
  }
  & .cont {
    display: flex;
    align-items: center;
  }
  & .option {
    padding: 5px 9px;
    border-radius: 3px;
    font-size: 1.5rem;
    margin-right: 2rem;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background-color: rgba($color-primary, 0.5);
    }
    &.active {
      background: $color-primary;
      color: #fff;
    }
  }
  &__box {
    position: fixed;
    z-index: 5;
    margin-top: 1rem;
    padding: 2rem 1rem;
    background: $color-white;
    @media only screen and (max-width: $bp-small) {
      margin-top: 0;
    }
  }
}
