.header {
  grid-column: full-start / full-end;
  padding: 10px;

  &__nav {
    display: flex;
    flex-direction: row;
  }
  &__link {
    color: $color-white;
    box-shadow: "none";
    text-decoration: none;
  }
  &__logo {
    display: inline-block;
    background-size: cover !important;
    fill: $color-secondary;
    width: 90px;
    height: 90px;
    @media only screen and (max-width: $bp-xsSmall) {
      width: 60px;
      height: 60px;
    }
  }
  &__number {
    padding: 0.5rem;
  }
}

// material ui overwrites
button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  padding: 0.5rem 5rem;
  @media only screen and (max-width: $bp-xsSmall) {
    padding: 0.5rem 3rem;
  }
}
.MuiButton-root:hover .MuiButton-label {
  color: $color-white;
}
