%heading {
  font-family: $font-display;
  font-weight: 400;
}

.heading-1 {
  @extend %heading;
  font-size: 5rem;
  color: $color-grey-light-1;
  line-height: 1;
}
.heading-1-big {
  @extend %heading;
  font-size: 7rem;
  color: $color-grey-light-1;
  line-height: 1;
  @media only screen and (max-width: $bp-xsSmall) {
    font-size: 6rem;
  }
}

.heading-2 {
  @extend %heading;
  font-size: 4rem;
  font-style: italic;
  line-height: 1;

  &--light {
    color: $color-grey-light-1;
  }
  &--dark {
    color: $color-grey-dark-1;
  }
}
.heading-2-small {
  @extend %heading;
  font-size: 2.5rem;
  font-style: italic;
  line-height: 1;

  &--light {
    color: $color-grey-light-1;
  }
  &--dark {
    color: $color-grey-dark-1;
  }
}

.heading-3 {
  @extend %heading;
  font-size: 2rem;
  color: $color-primary;
  text-transform: uppercase;
}
.heading-3-big {
  @extend %heading;
  font-size: 4rem;
  color: $color-primary;
  text-transform: uppercase;
  @media only screen and (max-width: $bp-xsSmall) {
    font-size: 3rem;
  }
}

.heading-4 {
  @extend %heading;
  font-size: 1.9rem;

  &--light {
    color: $color-grey-light-1;
  }
  &--dark {
    color: $color-grey-dark-1;
  }
}

.mb-sm {
  margin-bottom: 2rem;
}
.mb-md {
  margin-bottom: 3rem;
}
.mb-lg {
  margin-bottom: 4rem;
}
.mb-hg {
  margin-bottom: 8rem;
}
