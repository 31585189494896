.footer {
  grid-column: full-start / full-end;
  text-align: center;
  padding: 40px 0px;
  background: $color-secondary;

  &__logo {
    fill: $color-primary;
    width: 10rem;
    height: 10rem;
    display: inline-block;
    transition: all 0.2s;
  }
  &__logo:hover {
    fill: $color-primary-dark;
    transform: translateY(-2px);
  }
  &__copyright {
    font-size: 1.4rem;
    color: $color-grey-light-2;
    margin-top: 6rem;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    width: 70%;

    & a {
      color: $color-grey-light-2;
      text-decoration: none;

      &:hover {
        color: $color-primary;
      }
    }
  }
}

.nav {
  list-style: none;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2rem;
  align-items: center;

  &__link:link,
  &__link:visited {
    font-size: 1.4rem;
    color: $color-white;
    text-decoration: none;
    font-family: $font-display;
    text-transform: uppercase;
    text-align: center;
    padding: 1.5rem;
    display: block;
    transition: all 0.2s;
  }
}
a.nav__link:hover {
  background-color: rgba(#fff, 0.05);
  transform: translateY(-3px);
}
